.camera {
    .react-p5 {
        position: absolute;
        padding-bottom: 150%;
        width: 100%;
        overflow: hidden;
        canvas, video {
            position: absolute;
            top: 0;
            left: -50%;
            width: 200% !important;
            height: 100% !important;
        }
        // canvas { transform: rotate(180deg); }
    }
    > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0 !important;
        &:not([src]){
            display: none;
        }
    }
    
    .options-wrapper {
        position: absolute;
        bottom: 10rem;
        button {
            margin-left: 1rem;
            margin-right: 1rem;
            background: rgba(255,255,255,.5);
            color: #000;
            &.selected {
                background: #fff;
            }
        }
    }
    .previewCanvas {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0 !important;
        width: 100vw;
        height: 100vh;
    }

    &.vert {
        .react-p5 {
            canvas {
                left: 0;
                width: 100% !important;
            }
        }
    }
}
.take-pic {
    position: absolute;
    bottom: 3rem;
}
.countdown {
    position: absolute;
    text-align: center;
    display: block;
    color: #000;
    font-size: 4em;
}
.processing-msg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255,255,255,.5);
    color: #000;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
}