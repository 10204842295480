.modal {
    position: fixed;
    top: 2em;
    left: 2em;
    width: calc(100vw - 4em);
    height: calc(100vh - 4em);
    background: rgba(0,0,0,.8);
    z-index: 9999;
    margin: 0 !important;
    padding: 4em;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;

    p { font-size: .85em; margin-bottom: 1.6em; }
}