.preview {
    .nav {
        display: flex;
        width: 100%;
        justify-content: space-between;
        z-index: 9999;
        flex-direction: row-reverse;
    }
    .preview-wrapper {
        position: relative;
        // padding-bottom: 150%;
        width: 100%;
        // overflow: hidden;
        img {
            // position: absolute;
            // top: 0;
            // left: -50%;
            // width: 200% !important;
            // height: 100% !important;
            // max-width: unset;
        }
    }
    .options {
        button {
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,.8);
        color: #000;
        margin: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #fff;
            width: 40%;

            p { text-align: center; }
            input { 
                background: none;
                border: none;
                border-bottom: 1px solid #fff;
                color: #fff;
                font-size: 2em;
            }
            img { width: 100%; }
            button {
                margin-top: 1em;
            }
        }
    }
}