.signup {
    > div {
        width: 100%;
        &.error {
            color: #cc0000;
            input[type=text], input[type=email] {
                border-bottom-color: #cc0000;
            }
        }
        input[type=text], input[type=email] { font-size: 1.5em; }
    }
    label, input[type=text], input[type=email], input[type=submit] {
        display: block;
        width: 100%;
    }
    div.wrap-checkbox {
        display:flex;
        align-items: flex-end;
        input { order:1; width: 2em; height: 2em; margin-right: 2em; }
        label { order:2; }
    }
}