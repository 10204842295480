@import './scss/variables';
@import './scss/fonts';

.App {
  background-color: #001716;
  text-align: center;
  min-height: 100vh;

  .fullscreen {
    min-height: 100vh;
    box-sizing: border-box;
    width: 72%;
    max-width: 800px;
    margin: 0 auto;
    padding: 3em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: left;
    font-size: 18px;
    @media (min-width: 600px) {
      font-size: 24px;
    }

    > * {
      margin: 1rem 0;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    width: 100%;
  }
  p {
    font-weight: 300;
    width: 100%;
  }
  img {
    max-width: 100%;
  }
  button, input[type=submit], .button {
    font-size: inherit;
    font-family: inherit;
    padding: 1em;
    border-radius: 4em;
    border: none;
    text-decoration: none;
    * {
      pointer-events: none;
    }
  }
  input[type=text], input[type=email] { 
      background: none;
      border: none;
      border-bottom: 1px solid #fff;
      color: #fff;
      font-size: 2em;
      border-radius: 0;
  }
}



// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

.App-header {
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 2em;
  color: white;
  position: absolute;
  box-sizing: border-box;
  width: 100vw;
  top: 0;
  z-index: 999;

  .App-logo {
    max-width: 23vw;
    pointer-events: none;
  }

  .App-logo-mobile { display: none; }
  @media screen and (max-width: 720px) {
    .App-logo:has(+.App-logo-mobile){
      display: none;
    }
    .App-logo-mobile {
      display: block;
      max-width: 23vw;
      pointer-events: none;
    }
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 720px), (orientation:landscape) {
  .App .fullscreen:not(.mobile):after {
      opacity:1;
      content: 'This application was not designed for this display.';
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      justify-content: center;
      display: flex;
      align-items: center;
      background: #222222ee;
      z-index: 99;
      padding: 2em;
      box-sizing: border-box;
      text-align: center;
      color: white;
  }
}